import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
} from '@mui/material';
import axios from 'axios';


export default function AddressInput({ onChange , address }) {
   
  const [cep, setCep] = useState(address.cep);
  const [logradouro, setLogradouro] = useState(address.logradouro);
  const [bairro, setBairro] = useState(address.bairro);
  const [cidade, setCidade] = useState(address.cidade);
  const [estado, setEstado] = useState(address.estado);
  const [numero, setNumero] = useState(address.numero);
  const [complemento, setComplemento] = useState(address.complemento);

  useEffect(()=>{
    console.log(address);
    setCep(address.cep);
    setLogradouro(address.logradouro);
    setBairro(address.bairro);
    setCidade(address.cidade);
    setEstado(address.estado);
    setNumero(address.numero);
    setComplemento(address.complemento);
  }, [address])

  const handleCepChange = async (e) => {
    //console.log(address)
    const newCep = e.target.value;
    setCep(newCep);

    if (newCep.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${newCep}/json/`);
        const { logradouro, bairro, localidade, uf } = response.data;
        setLogradouro(logradouro);
        setBairro(bairro);
        setCidade(localidade);
        setEstado(uf);

        onChange(newCep, logradouro, bairro, localidade, uf, numero, complemento);
      } catch (error) {
        console.error('Erro ao buscar informações de endereço:', error.message);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'numero') {
      setNumero(value);
    } else if (name === 'complemento') {
      setComplemento(value);
    }
    
    onChange(cep, logradouro, bairro, cidade, estado, numero, complemento);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="CEP"
          name="cep"
          value={cep}
          defaultValue={cep}
          onChange={handleCepChange}
          required
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          label="Logradouro"
          name="logradouro"
          value={logradouro}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="Número"
          name="numero"
          value={numero}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          label="Complemento"
          name="complemento"
          value={complemento}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Bairro"
          name="bairro"
          value={bairro}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="cidade"
          name="cidade"
          value={cidade}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="UF"
          name="uf"
          value={estado}
          disabled
        />
      </Grid>
    </Grid>
  );
};

