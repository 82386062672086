// MapPage.js
import React, { useState, useEffect } from 'react';
import {APIProvider, AdvancedMarker, Map, Marker, useAdvancedMarkerRef, useMap} from '@vis.gl/react-google-maps';
import { Container } from '@mui/material';

const MapComponent = (props) => {
  const [markerRef, setMarkerRef] = useAdvancedMarkerRef()
  const map = useMap();
  return (
  
    <Map
      zoom={12} 
      center={props.center}
    >
      <Marker ref={markerRef} position={props.center} />
      { 
        props.markers.filteredMarkers.map((marker, index) => {	
          return <Marker ref={markerRef} position={{lat: marker.lat, lng: marker.lng}} />
        })
      } 
    </Map>
  )


};

const ContentMap = (filteredMarkers) => {
  const [userLocation, setUserLocation] = useState(null);
  useEffect(() => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Erro ao obter localização do usuário:', error);
        }
      );
  }, []);
 
  return (
    <div>
      <Container style={{width: '100%', height: '400px'}}>
        <APIProvider  apiKey={"AIzaSyBVhtDdvRmH-AaWpUfgKGL8G-kdZzgzY_k"}>
        {/* Google Maps component */ }
        { (
          <MapComponent
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            center={userLocation}
            markers={filteredMarkers}
          />
        )}
        </APIProvider>
      </Container>
    </div>
  );
};

export default ContentMap;
