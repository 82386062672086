import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Container,
  InputAdornment,
} from '@mui/material';
import api from '../../Api/api';
import AddressInput from './AdressInput';
import { Facebook, Instagram, WhatsApp } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import AlertSnackbar from './AlertSnackBar';
import LoadingIndicator from './LoadingIndicator';

const FollowersForm = () => {
  const { token } = useAuth();
  const {id} = useParams();
  const [formData, setFormData] = useState({
    id: '',
    nome: '',
    email: '',
    nome_publicacao: '',
    endereco: '',
    telefone: '',
    whatsapp: '',
    instagram: '',
    facebook: '',
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [address, setAddress] = useState({
    cep: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    estado: '',
    numero: '',
    complemento: ''
  });

  useEffect(() => {
    setLoading(true);
    api.get(`/api/discipulos/${id}`, token )
      .then((response) => {
        console.log(response.data);
        setFormData(response.data);
        setAddress({
          cep: response.data.cep,
          logradouro:response.data.logradouro,
          bairro: response.data.bairro,
          cidade: response.data.cidade,
          estado: response.data.estado,
          numero: response.data.numero,
          complemento: response.data.complemento,
        })
        showSnackbar('Dados carregados com sucesso!', 'success');
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching discipulos:', error);
        showSnackbar('Houve um erro ao salvar, tente novamente mais tarde!', 'error');
        setLoading(false);
      });
    
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAdressChange = (cep, logradouro, bairro, cidade, estado, numero, complemento) => {
    //console.log()
    setFormData((prevData) => ({
      ...prevData,
      cep: cep,
      logradouro:logradouro,
      bairro: bairro,
      cidade: cidade,
      estado: estado,
      numero: numero,
      complemento: complemento,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    api.put(`/api/discipulos`, formData, token)
      .then((response) => {
        if(response.data.status == 500) {
          showSnackbar(response.data.message, 'error');
          setLoading(false);
          return;
        }
        setFormData(response.data.discipulo);
        showSnackbar('Dados alterados com sucesso!', 'success');
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error saving discipulos:', error);
        showSnackbar('Erro ao salvar, tente novamente mais tarde!', 'error');
        setLoading(false);
      });
    console.log('Dados enviados:', formData);
    //setLoading(false);
  };

  return (
    <Container spacing={5}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2>Dados do Discípulo</h2>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Nome"}
              value={formData.nome}
              readOnly
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={"E-mail"}
              value={formData.email}
              readOnly
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              
              label={"Conclusão"}
              value={formData.data_conclusao}
              readOnly
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="80%">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>Dados de Publicação</h2>
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth
                label="Nome de Publicação"
                name="nome_publicacao"
                value={formData.nome_publicacao}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <AddressInput 
                onChange={handleAdressChange} 
                address={address} 
              />
              
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Telefone"
                name="telefone"
                value={formData.telefone}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="WhatsApp"
                name="whatsapp"
                value={formData.whatsapp}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WhatsApp />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Instagram"
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Instagram />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Facebook"
                name="facebook"
                value={formData.facebook}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Facebook />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              
              <Button type="submit" variant="contained" color="primary" sx={{margin: '0 auto'}}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
        <AlertSnackbar
            open={snackbarOpen}
            handleClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        <LoadingIndicator open={loading}/>
      </Container>
    </Container>
  );
};

export default FollowersForm;