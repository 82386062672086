import React, { useState } from 'react'
import { TextField, Button, Typography, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import api from '../../Api/api'

export default function ForgotPasswordPage() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setMessage('')
    try {
      const data = await api.post('/api/forgot-password', { email })
      if (data.status === 200) {
        setMessage('Email enviado com sucesso. Verifique sua caixa de entrada.')
      } else {
        setError(
          'Falha ao enviar email. Verifique seu e-mail e tente novamente.'
        )
      }
    } catch (error) {
      setError('Falha ao enviar email. Verifique seu e-mail e tente novamente.')
      console.error('Erro ao enviar email:', error)
    }
  }

  return (
    <div className="login-form-container" style={{background: '#000333', width: '100%', margin: 0}}>
      <div className="logo">
        <img src="../logo-escuro.png" alt="Logo" style={{ width: '250px' }} />
      </div>
      <Typography component="h1" variant="h5" style={{color: 'white'}}>
        Esqueci Minha Senha
      </Typography>
      <form  className="login-form" onSubmit={handleSubmit}>
        <input
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="E-mail"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Enviar
        </Button>
        <br />
        <Button fullWidth variant="contained" color="primary" onClick={() => navigate('/login')}>
          Entrar
        </Button>
      </form>
      {message && <Typography color="error">{message}</Typography>}
    </div>
  )
}
