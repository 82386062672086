import React, { useState } from 'react';
import PrivateAppBar from '../components/Principal/PrivateAppBar';
import Drawer from '../components/Principal/Drawer';

const Main = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <PrivateAppBar handleDrawerOpen={handleDrawerOpen} />
      <Drawer open={open} handleDrawerClose={handleDrawerClose} />
    </div>
  );
};

export default Main;