import React from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FollowTheSignsOutlined, FollowTheSignsSharp, Settings } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const MyDrawer = ({ open, handleDrawerClose }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  console.log(user.discipulo);
 

  const menuItems = [
    { text: 'Meus Dados', icon: <FollowTheSignsOutlined />, path: `/discipulos/dados/${user.discipulo?.id}`, roles: ['discipulo'] },
    { text: 'Discípulos', icon: <FollowTheSignsSharp />, path: '/admin/discipulos', roles: ['admin'] },
    { text: 'Configurações', icon: <Settings />, path: '/admin/configuracoes', roles: ['admin'] },
  ];

  return (
    <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
      <div>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <List>
        {menuItems.filter(item => item.roles.includes(user.role)).map((item, index) => (
          <ListItemButton LinkComponent={Link} to={item.path} key={index} >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
      
    </Drawer>
  );
};

export default MyDrawer;