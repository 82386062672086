import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon'
import PlaceIcon from '@mui/icons-material/Place'
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Icon, IconButton } from '@mui/material';
import { Facebook, Instagram, Mail, Phone, WhatsApp } from '@mui/icons-material';

const ListOfDiscipulos = (filteredMarkers) => {

  const handleInnerClick = (url, target, event) => {
    // Impede a propagação do evento para o botão externo
    event.stopPropagation();
    // Abre a URL desejada em uma nova guia
    window.open(url, target);
  };

  return (
    <div>
      {/* List of points */}
      <List>
        {filteredMarkers.filteredMarkers.map((marker, index) => (
          <ListItem disablePadding>
          <ListItemButton key={index}>
            <ListItemIcon>
              <PlaceIcon />
            </ListItemIcon>
            <ListItemText 
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="h5"
                    color="text.primary"
                  >
                    {marker.nome}
                  </Typography>
                </React.Fragment>
              } 
              secondary={
                <>
                <div>
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body1"
                    color="text.primary"
                  >
                    {marker.endereco}
                  </Typography>
                </React.Fragment>
                </div>
                <div>
                <React.Fragment>
                  {marker.telefone && 
                  <Typography
                    sx={{ display: 'inline', marginRight: '10px' }}
                    component="span"
                    variant="body1"
                    color="text.primary"
                  >
                    <Icon size={'small'}><Phone/></Icon>
                    {marker.telefone}
                  </Typography>
                  }
                  { marker.email &&
                  <Typography
                    sx={{ display: 'inline', marginRight: '10px' }}
                    component="span"
                    variant="body1"
                    color="text.primary"
                  >
                    <Icon size={'small'}><Mail/></Icon>
                    {marker.email}
                  </Typography>
                  }
                </React.Fragment>
                </div>
                <div>
                <React.Fragment  >
                  {marker.whatsapp && (
                  <IconButton onClick={(event) => handleInnerClick(`https://wa.me/55${marker.whatsapp}`, '_blank', event)} size={'small'} color='default' sx={{marginRight: '10px'}}>
                    <WhatsApp/>
                    {marker.whatsapp}
                  </IconButton>
                  )}
                  {marker.instagram &&
                  <IconButton onClick={(event) => handleInnerClick(`https://instagram.com/${marker.instagram}`, '_blank', event)} size={'small'} color='default' sx={{marginRight: '10px'}}>
                    <Instagram/>
                    {marker.instagram}
                  </IconButton>
                  }
                  {marker.facebook &&
                  <IconButton onClick={(event) => handleInnerClick(`https://facebook.com/${marker.facebook}`, '_blank', event)} size={'small'} color='default' sx={{marginRight: '10px'}}>
                    <Facebook/>
                    {marker.facebook}
                  </IconButton>
                  }
                </React.Fragment>
                </div>
              </>
              }
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
      
    </div>
  )
}

export default ListOfDiscipulos;

