import { AppBar, Toolbar, Typography } from "@mui/material";

const PublicAppBar = () => {
  return (
  <div>
    {/* Appbar */}
    <AppBar position="static" style={{ background: '#000333'}}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Your logo component */}
        <div></div>
        <div>
          {/* Add your logo component here */}
          { <img src={process.env.REACT_APP_PUBLIC_URL+process.env.REACT_APP_URL_LOGO} alt="Logo" style={{ width: '200px', marginRight: '10px' }} /> }
        </div>
        <div></div>
        {/* Title */}
        
      </Toolbar>
    </AppBar>
    </div>
  );
};

export default PublicAppBar;