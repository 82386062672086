import React, { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Box
} from '@mui/material';
import api from '../../Api/api';
import { useAuth } from '../../context/AuthContext';

const FollowerpprovalModal = ({ open, onClose, onApprove, discipuloSelecionado }) => {
  const { token } = useAuth();
  const [dataConclusao, setDataConclusao] = useState('');

  const handleApprove = () => {
    discipuloSelecionado.data_conclusao = dataConclusao;
    api.put(`/api/discipulos/approve/${discipuloSelecionado.id}`, discipuloSelecionado, token)
    onApprove(discipuloSelecionado, dataConclusao);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'white',
          p: 3,
        }}
      > <h2>Aprovar discipulo</h2>
        <p>Nome: {discipuloSelecionado?.nome}</p>
        <TextField
          label="Data de Conclusão"
          value={dataConclusao}
          onChange={(e) => setDataConclusao(e.target.value)}
        />
        <Button variant="contained" onClick={handleApprove}>
          Aprovar
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        </Box>
    </Modal>
  );
};

export default FollowerpprovalModal;