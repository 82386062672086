import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '../../context/AuthContext';
import ChangePasswordModal from '../Auth/ChangePasswordModal';

const PrivateAppBar = ({ handleDrawerOpen }) => {
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalChangePassword, setOpenModalChangePassword] = React.useState(false);

  const handleOpenModalChangePassword = () => {
    setOpenModalChangePassword(true);
  };

  const handleCloseModalChangePassword = () => {
    setOpenModalChangePassword(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" style={{background: '#000333'}}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
        <div >
          {/* Add your logo component here */}
          { <img src={process.env.REACT_APP_PUBLIC_URL+process.env.REACT_APP_URL_LOGO} alt="Logo" style={{ width: '200px', marginRight: '10px' }} /> }
        </div>
        <div>
          <IconButton color="inherit">
            <SettingsIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleMenu}>
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenModalChangePassword}>Alterar Senha</MenuItem>
            <MenuItem onClick={logout}>Sair</MenuItem>
          </Menu>
          <ChangePasswordModal open={openModalChangePassword} handleClose={handleCloseModalChangePassword}/>
        </div>
      </Toolbar>
    </AppBar>
    
  );
};

export default PrivateAppBar;