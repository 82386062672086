import LoginForm from "../components/Auth/LoginForm";

const Login = () => {
  return(
    <div style={{background: '#000333', width: '100%', margin: 0}}>
      <LoginForm/>
    </div>
  );
}

export default Login;