import { Search } from '@mui/icons-material';
import { IconButton, List, ListItem, ListItemButton, TextField } from '@mui/material';
import React, { useState } from 'react';

function Localizador({onHandleUserLocate}) {
  const [busca, setBusca] = useState('');
  const [resultados, setResultados] = useState([]);
  const [localSelecionado, setLocalSelecionado] = useState(null);

  const handleBuscar = async () => {
    try {
      const apiKey = 'AIzaSyBVhtDdvRmH-AaWpUfgKGL8G-kdZzgzY_k';
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${busca}&fields=geometry&key=${apiKey}`
      );
      const data = await response.json();
      if (data.predictions) {
        setResultados(data.predictions);
      }
    } catch (error) {
      console.error('Erro ao buscar locais:', error);
    }
  };

  const handleLocalSelecionado = async (local) => {
    setLocalSelecionado(local);
    try {
      const apiKey = 'AIzaSyBVhtDdvRmH-AaWpUfgKGL8G-kdZzgzY_k';
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${local.place_id}&key=${apiKey}`
      );
      const data = await response.json();
      if (data.result && data.result.geometry && data.result.geometry.location) {
        const { lat, lng } = data.result.geometry.location;
        onHandleUserLocate(lat, lng)
      }
    } catch (error) {
      console.error('Erro ao obter detalhes do local:', error);
    }
        

    setResultados([]);
  };

  return (
    <div>
      <TextField
        placeholder="Digite o CEP ou nome da cidade/estado"
        value={busca}
        style={{width: '60%'}}
        onChange={(e) => setBusca(e.target.value)}
      />
      <IconButton onClick={handleBuscar}><Search /></IconButton>

      <List>
        {resultados.map((local) => (
          <ListItem key={local.place_id} onClick={() => handleLocalSelecionado(local)}>
            <ListItemButton>
              {local.description}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {localSelecionado && (
        <div>
          <h3>Local selecionado:</h3>
          <p>{localSelecionado.description}</p>
          {/* Aqui você pode exibir os 10 locais mais próximos */}
        </div>
      )}
    </div>
  );
}

export default Localizador;
