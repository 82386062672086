import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingIndicator from '../General/LoadingIndicator';
import AlertSnackbar from '../General/AlertSnackBar';
import api from '../../Api/api';
import { useAuth } from '../../context/AuthContext';

const ChangePasswordModal = ({ open, handleClose }) => {
  const { token } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // Adicione aqui a lógica de validação ou submissão do formulário
    if (newPassword !== confirmPassword) {
      alert('As novas senhas não coincidem.');
      return;
    }
    
    api.post(`/api/change/password`, {current_password: currentPassword, new_password: newPassword, confirm_password: confirmPassword}, token )
      .then((response) => {
        showSnackbar('Senha alterada com sucesso!', 'success');
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching discipulos:', error);
        showSnackbar('Houve um erro ao salvar, tente novamente mais tarde!', 'error');
        setLoading(false);
      });

    console.log('Senha alterada com sucesso!');
    handleClose(); // Fechar o modal após a submissão
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Alterar Senha
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <LoadingIndicator open={loading} />
          <TextField
            margin="dense"
            id="current-password"
            label="Senha Atual"
            type="password"
            fullWidth
            variant="outlined"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            id="new-password"
            label="Nova Senha"
            type="password"
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            id="confirm-password"
            label="Confirmar Nova Senha"
            type="password"
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit">Alterar Senha</Button>
          <AlertSnackbar
            open={snackbarOpen}
            handleClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePasswordModal;