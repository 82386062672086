import React, { useState, useEffect } from 'react';
import api from '../../Api/api';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  styled,
  tableCellClasses,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FollowerpprovalModal from './FollowerApprovalModal';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000333",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const FollowerList = () => {
  const { token } = useAuth();
  const [discipulos, setDiscipulos] = useState([]);
  const [discipuloSelecionado, setDiscipuloSelecionado] = useState(null);
  const [dataConclusao, setDataConclusao] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    api.get('/api/discipulos', token)
      .then((response) => {
        setDiscipulos(response.data);
      })
      .catch((error) => {
        console.error('Error fetching discipulos:', error);
      });
  }, []);

  const handleOpenApprovalModal = (discipulo) => {
    console.log(discipulo)
    setDiscipuloSelecionado(discipulo);
    setOpen(true)
  };

  const handleCloseApprovalModal = () => {
    setDiscipuloSelecionado(null);
    setOpen(false);
  }

  const handleApprove = () => {
    // Implement your approval logic here
    console.log('User approved:', discipuloSelecionado, 'Completion Date:', dataConclusao);
    setOpen(false);
  };

  const statusName = (status) => {
    if (status === 1) return 'Pendente de Publicação';
    if (status === 2) return 'Publicado';
    if (status === 3) return 'Cancelado';
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell>Data de Conclusão</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discipulos.map((discipulo) => (
              <StyledTableRow key={discipulo.id}>
                <StyledTableCell>{discipulo.nome}</StyledTableCell>
                <StyledTableCell>{discipulo.data_conclusao}</StyledTableCell>
                <StyledTableCell>{statusName(discipulo.status)}</StyledTableCell>
                <StyledTableCell>
                  <IconButton LinkComponent={Link} to={`/admin/discipulos/${discipulo.id}`} >
                    <EditIcon />
                  </IconButton>
                  {discipulo.status == 1 && <IconButton onClick={() => handleOpenApprovalModal(discipulo)}>
                    <CheckCircleIcon />
                  </IconButton>}
                  {discipulo.status != 3 && <IconButton>
                    <CancelIcon />
                  </IconButton>}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Approval */}
      {open && (
        <FollowerpprovalModal
          open={open}
          onClose={handleCloseApprovalModal}
          onApprove={handleApprove} // Implemente sua lógica de aprovação aqui
          discipuloSelecionado={discipuloSelecionado}
        />
      )}
    </div>
  );
};

export default FollowerList;