import FollowersForm from "../components/General/FollowersForm";
import Main from "./Main";

const Followers = () => {
  return (
    <>
    <Main/>
    <FollowersForm/>
    </>
  );
}

export default Followers;