import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import PublicAppBar from '../components/Principal/PublicAppBar';
import ContentMap from '../components/Map/ContentMap';
import ListOfDiscipulos from "../components/Map/ListOfDiscipulos";
import Localizador from '../components/Map/Localizador';
import api from '../Api/api';

const MapPage = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [filteredMarkers, setFilteredMarkers] = useState([])
  const [markers, setMarkers] = useState([]);

  const handleUserLocal = (lat, lng) => {
    setUserLocation({lat: lat, lng: lng})
  }

  useEffect(()=>{
    const retorno = api.get('/api/discipulos/published');
    retorno
      .then((response) => {
        const listOfMarkers = response.data;
        console.log(listOfMarkers)
        setMarkers(listOfMarkers.map((discipulo) => {
          return {
            nome: discipulo.nome_publicacao,
            endereco: `${discipulo.cep}, ${discipulo.logradouro}, ${discipulo.numero}${discipulo.complemento?', '+discipulo.complemento:''}, ${discipulo.bairro}, ${discipulo.cidade}/${discipulo.estado}`,
            telefone: discipulo.telefone,
            email: discipulo.email,
            whatsapp: discipulo.whatsapp,
            facebook: discipulo.facebook,
            instagram: discipulo.instagram,
            lat: parseFloat(discipulo.latitude),
            lng: parseFloat(discipulo.longitude)
          }
        }))
        setFilteredMarkers(markers)
      })
      .catch((error) => {
        console.error('Error finding discipulos:', error);
      });
  }, []);

  useEffect(() => {
    setFilteredMarkers(markers);
    if (userLocation) {
      
      const markersWithDistances = filteredMarkers.map((marker) => ({
        ...marker,
        distance: calculateDistance(userLocation, marker),
      }));
      console.log(markersWithDistances);
      const proximityMarkers = markersWithDistances.filter(
        (a) => a.distance < 10
      );

      const sortedMarkers = proximityMarkers.sort(
        (a, b) => a.distance - b.distance
      );

      setFilteredMarkers(sortedMarkers);
    }
  }, [userLocation, markers, filteredMarkers]);

  

  const calculateDistance = (point1, point2) => {
    const R = 6371;
    const lat1 = (point1.lat * Math.PI) / 180;
    const lon1 = (point1.lng * Math.PI) / 180;
    const lat2 = (point2.lat * Math.PI) / 180;
    const lon2 = (point2.lng * Math.PI) / 180;

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance;
  };

  return (
    <div>
      <PublicAppBar></PublicAppBar>
      <Container style={{width: '80%', marginTop: 10}}>
        <ContentMap filteredMarkers={filteredMarkers}></ContentMap>
        <Container>
          <Localizador onHandleUserLocate={handleUserLocal}></Localizador>
        </Container>
        <ListOfDiscipulos filteredMarkers={filteredMarkers}></ListOfDiscipulos> 
      </Container>
    </div>
  );
};

export default MapPage;
