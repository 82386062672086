import { Box, Button, Icon, IconButton } from "@mui/material";
import FollowersList from "../components/General/FollowersList";
import Main from "./Main";
import { Autorenew } from "@mui/icons-material";
import api from "../Api/api";
import { useAuth } from "../context/AuthContext";
import LoadingIndicator from '../components/General/LoadingIndicator';
import AlertSnackbar from '../components/General/AlertSnackBar';
import { useState } from "react";

const FollowersListPage = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const sincronizar = () => {
    setLoading(true);
    api.get('/api/hotmart', token)
      .then(()=>{
        setLoading(false)
        showSnackbar('Sincronização realizada!', 'success');
      })
      .catch(() => {
        showSnackbar('Ocorreu um erro ao sincronizar!', 'error');
        setLoading(false)
      })
  }

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <>
    <Main/>
    <Box sx={{width: '85%', margin: '0 auto', paddingTop: 10}}>
      <div>
        <h2 style={{float: "left"}}>Discípulos</h2>
        <IconButton variant="contained" color="primary" style={{float: "right"}} onClick={() => {sincronizar()}}>
          <Icon> <Autorenew/></Icon>
          <p>Sincronizar</p>
        </IconButton>
      </div>
      <FollowersList/>
      <AlertSnackbar
            open={snackbarOpen}
            handleClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
      <LoadingIndicator open={loading}/>
    </Box>
    </>
  );
}

export default FollowersListPage;