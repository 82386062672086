// src/components/LoginForm.js

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import './LoginForm.css'
import { Typography } from '@mui/material'
import api from '../../Api/api'

const LoginForm = () => {
  const navigate = useNavigate()
  const { login } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleLogin = async (e) => {
    e.preventDefault()
    setError('')
    try {
      const data = await api.post('/api/login', { email, password })
      if (data.status === 200) {
        const { access_token, user } = data.data
        login(access_token)
        console.log(user)
        localStorage.setItem('user', JSON.stringify(user))
        if (user.role === 'admin') {
          navigate(`/admin/discipulos`)
        }

        if (user.role === 'discipulo') {
          navigate(`/discipulos/dados/${user.discipulo.id}`)
        }
      } else {
        setError('Falha ao entrar no sistema. Verifique seu e-mail e senha.')
      }
    } catch (error) {
      setError('Falha ao entrar no sistema. Verifique seu e-mail e senha.')
      console.error('Erro ao fazer login:', error)
    }
  }

  return (
    <div className="login-form-container">
      <div className="logo">
        <img src="../logo-escuro.png" alt="Logo" style={{ width: '250px' }} />
      </div>
      <form className="login-form" onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Typography color="error">{error}</Typography>}
        <button type="submit">Entrar</button>
        <br />
        <button onClick={() => navigate('/forgot-password')}>
          Esqueci Senha
        </button>
      </form>
    </div>
  )
}

export default LoginForm
