import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import PrivateRoute from './components/Auth/PrivateRoute'
import './App.css'
import MapPage from './pages/MapPage'
import Followers from './pages/Followers'
import FollowersListPage from './pages/FollowersListPage'
import Login from './pages/Login'
import ForgotPasswordPage from './components/Auth/ForgotPasswordPage'
import ResetPasswordPage from './components/Auth/ResetPasswordPage'

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<MapPage />} />
          <Route path="/publico/" element={<MapPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/discipulos/dados/:id"
            element={
              <PrivateRoute>
                <Followers />
              </PrivateRoute>
            }
          />
          <Route
            path="/discipulos"
            element={
              <PrivateRoute>
                <Followers />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/discipulos"
            element={
              <PrivateRoute>
                <FollowersListPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/discipulos/:id"
            element={
              <PrivateRoute>
                <Followers />
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={
              <Login/>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default App
