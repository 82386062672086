import { createContext, useContext, useState } from 'react';

const decodeJWT = (token) => {
  const base64Url = token.split('.')[1]; // Obtém a parte do payload
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Corrige os caracteres para base64 padrão
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload); 
};

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const login = (newToken) => {
    setToken(newToken);
  };

  const logout = () => {
    setToken(null);
  };

  const getPayload = () => {
    return decodeJWT(token);
  }

  return (
    <AuthContext.Provider value={{ token, login, logout, getPayload }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};