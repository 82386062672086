import React, { useState }  from 'react'
import { useSearchParams } from 'react-router-dom'
import { TextField, Button, Typography, Container } from '@mui/material'
import api from '../../Api/api'
import './LoginForm.css'

export default function ResetPasswordPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setMessage('')
    try {
      const data = await api.post('/api/reset-password', { token: searchParams.get("token"), email: searchParams.get("email"), new_password: password, confirm_password: confirmPassword })
      if (data.status === 200) {
        setMessage('Senha alterada com sucesso!.')
      } else {
        setError(
          'Falha ao resetar a senha.'
        )
      }
    } catch (error) {
      setError('Falha ao resetar a senha.')
      console.error('Erro ao enviar email:', error)
    }
  }

  return (
    <div className="login-form-container" style={{background: '#000333', width: '100%', margin: 0}}>
      <div className="logo">
        <img src="../logo-escuro.png" alt="Logo" style={{ width: '250px' }} />
      </div>
      <Typography component="h1" variant="h5" style={{color: 'white'}}> 
        Esqueci Minha Senha
      </Typography>
      <form className="login-form" onSubmit={handleSubmit}>
        <input
          margin="dense"
          id="new-password"
          placeholder="Nova Senha"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          margin="dense"
          id="confirm-password"
          placeholder="Confirmar Nova Senha"
          type="password"
          fullWidth
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Enviar
        </Button>
      </form>
      {message && <Typography color="error">{message}</Typography>}
    </div>
    
    
  )
}
