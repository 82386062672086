import axios from "axios";

const api = {
  get:  async (path, token) => {
    console.log(process.env.REACT_APP_URL_API+`${path}`);

    return await axios.get(process.env.REACT_APP_URL_API+`${path}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : null
      }
    })
  },

  post: async (path, data, token) => {
    console.log(process.env.REACT_APP_URL_API+`${path}`)
    const retorno = await axios.post(process.env.REACT_APP_URL_API+`${path}`, JSON.stringify(data), {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : null
        },
      });
    
    return retorno;
  },

  put: async (path, data, token) => {
    console.log(process.env.REACT_APP_URL_API+`${path}`)
    const retorno = await axios.put(process.env.REACT_APP_URL_API+`${path}`, JSON.stringify(data), {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : null
        },
      });
    
    return retorno;
  },
}

export default api;